






import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import makeSelectOptions from "@common/util/makeSelectOptions";
import actionLogActionTypes from "@/constants/actionLogActionTypes";
import actionLogTargetTypes from "@/constants/actionLogTargetTypes";
import orderActionScenarios from "@/constants/orderActionScenarios";
import moment from "moment";
import { displayItemsWithName } from "@/utils/marketItemDisplay";

function itemDownloadButton(index) {
  return {
    attrs: {},
    content: {
      icon: "mdi-download",
    },
    on: {
      xClick({ self }) {
        const { item } = self.context();
        console.log(item);
        coreApiClient.download(
          "actionLogs",
          `${item._id}/files/${item.fileItems[index].uuid}`
        );
      },
    },
    target: {
      tooltip: {
        content: {
          text: `Download File ${+index + 1}`,
        },
      },
    },
    ext: {
      condition(_, item) {
        return item && item.fileItems && item.fileItems[index];
      },
    },
  };
}

export default Vue.extend({
  data() {
    return {
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          urlEnabled: true,
          search: {
            ext: {
              hidden: true,
            },
          },
          filters: {
            filters: {
              _id: {
                attrs: {
                  label: "ID",
                },
                rules: {
                  equal_to_id: {},
                },
              },
              createdTime: {
                type: "XDateTimePicker",
                attrs: {
                  label: "Ngày tạo",
                },
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (bắt đầu)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.startOf("day"),
                        },
                      },
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày tạo (kết thúc)",
                      xOptions: {
                        content: {
                          resultFilter: (m) => m.endOf("day"),
                        },
                      },
                    },
                  },
                },
              },
              actionType: {
                type: "select",
                attrs: {
                  label: "Hành động",
                  items: makeSelectOptions(actionLogActionTypes),
                },
                rules: {
                  equal_to: {},
                },
              },
              targetType: {
                type: "select",
                attrs: {
                  label: "Đối tượng",
                  items: makeSelectOptions(actionLogTargetTypes),
                },
                rules: {
                  equal_to: {},
                },
              },
              targetId: {
                attrs: {
                  label: "ID đối tượng",
                },
                rules: {
                  equal_to: {},
                },
              },
              userId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Người thực hiện",
                  required: true,
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "users",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              remoteIp: {
                type: "text",
                attrs: {
                  label: "IP",
                },
                rules: {
                  equal_to: {},
                },
              },
              "actionParams.xShopId": {
                type: "XAutocomplete",
                attrs: {
                  label: "Shop đồng bộ",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "shops",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              targetIds: {
                type: "XAutocomplete",
                attrs: {
                  label: "Shop",
                  "item-value": "_id",
                  "item-text": "name",
                  "item-disabled": "_",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call(
                          "shops",
                          "findAll",
                          {
                            payload: JSON.stringify({
                              limit,
                              search,
                              filters: [
                                {
                                  key: "_id",
                                  operator: "equal_to",
                                  value,
                                },
                              ],
                            }),
                          }
                        );
                        return displayItemsWithName(items);
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          displayFields: {
            _id: {
              text: "ID",
              sortable: true,
              hidden: true,
            },
            user: {
              text: "Người thực hiện",
              sortable: true,
              options: {
                subProp: "user.name",
              },
            },
            actionType: {
              text: "Hành động",
              sortable: true,
              options: {
                label(value) {
                  return actionLogActionTypes[value || ""] || value;
                },
              },
            },
            targetType: {
              text: "Đối tượng",
              sortable: true,
              options: {
                label(value) {
                  return actionLogTargetTypes[value || ""] || value;
                },
              },
            },
            content: {
              text: "Nội dung",
              options: {
                preTransform(_, item) {
                  const target = {
                    ...(item.targetBefore || {}),
                    ...(item.targetAfter || {}),
                  };
                  if (item.actionType === "Login") {
                    return item.actionParams?.username;
                  }
                  switch (item.targetType) {
                    case "DailyBonusSetting":
                      return `${new Date(target.startTime)} - ${new Date(
                        target.endTime
                      )}`;
                    case "MktCost":
                      return `Thời gian: ${moment(target.time).format()}`;
                    case "MktLink":
                      return target.baseUrl;
                    case "Order": {
                      let orderIdText = target.orderId;
                      if (!orderIdText && item.targetOrderIds) {
                        orderIdText = item.targetOrderIds.join(", ");
                      }
                      const pathItems =
                        orderActionScenarios[item.actionScenario];
                      let scenarioText = "N/A";
                      if (pathItems) {
                        scenarioText = pathItems.join(" - ");
                      }
                      return `${orderIdText} => ${scenarioText}`;
                    }
                    case "ProductGroup":
                      if (item.actionType === "GenerateProducts") {
                        return item.actionParams.productSkus.join(", ");
                      }
                      return target.sku;
                    case "Product":
                      return target.sku;
                    case "WarehouseImport":
                      if (item.actionType === "CreateMany") {
                        if (item.actionParams.warehouseImportOrderIds) {
                          return `Nhập mới: ID: ${item.actionParams.warehouseImportIds.join(
                            ", "
                          )}`;
                        } else {
                          return `Return: ID: ${item.actionParams.warehouseImportIds.join(
                            ", "
                          )}`;
                        }
                      } else {
                        if (target.importType === "nm") {
                          return `Nhập mới: Sản phẩm: ${
                            (target.product && target.product.sku) ||
                            target.productId
                          }; Kho ${
                            (target.warehouse && target.warehouse.name) ||
                            target.warehouseId
                          }`;
                        } else {
                          return `Return: Sản phẩm: ${
                            (target.product && target.product.sku) ||
                            target.productId
                          }; Kho ${
                            (target.warehouse && target.warehouse.name) ||
                            target.warehouseId
                          }`;
                        }
                      }
                    case "WarehouseResetCommand":
                      return `Kho ${target.warehouseId}`;
                    case "AdsAgency":
                      if (target.nextFeePercentage) {
                        return `${target.name}: %phí=${
                          target.nextFeePercentage
                        } - ${moment(target.nextFeePercentageStartTime).format(
                          "DD/MM/YYYY"
                        )}`;
                      } else if (target.nextTariffItem) {
                        return `${target.name}: bảng phí - ${moment(
                          target.nextTariffItemsStartTime
                        ).format("DD/MM/YYYY")}`;
                      } else {
                        return target.name;
                      }
                    case "AdsAgencyAccount":
                      if (target.nextFeePercentage) {
                        return `${target.name}: %phí=${
                          target.nextFeePercentage
                        } - ${moment(target.nextFeePercentageStartTime).format(
                          "DD/MM/YYYY"
                        )}`;
                      } else if (target.nextTaxPercentage) {
                        return `${target.name}: %thuế=${
                          target.nextTaxPercentage
                        } - ${moment(target.nextTaxPercentageStartTime).format(
                          "DD/MM/YYYY"
                        )}`;
                      } else if (target.nextTariffItem) {
                        return `${target.name}: bảng phí - ${moment(
                          target.nextTariffItemsStartTime
                        ).format("DD/MM/YYYY")}`;
                      } else {
                        return target.name;
                      }
                    case "WarehouseExport":
                      return (
                        target.orders &&
                        target.orders.map((o) => o.orderId).join(", ")
                      );
                    default:
                      return target.name;
                  }
                },
              },
            },
            remoteIp: {
              text: "IP",
              sortable: true,
            },
            createdTime: {
              text: "Thời gian tác động",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          topActionButtons: {
            export: {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map(
                              (item) => item._id
                            ),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download(
                    "actionLogs",
                    `@/export`,
                    findAllOptions
                  );
                },
              },
            },
          },
          findAllOptionsFilter(options) {
            return options;
          },
          template: {
            itemActionButtons: {
              download0: itemDownloadButton(0),
              download1: itemDownloadButton(1),
              download2: itemDownloadButton(2),
              download3: itemDownloadButton(3),
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call(
                "actionLogs",
                "findAll",
                options
              );
              return [items, count];
            },
          },
          delete: {},
        },
      },
    };
  },
});
