export default {
    "Create": "Tạo mới",
    "Update": "Cập nhật",
    "Delete": "Xóa",
    "Login": "Đăng nhập",
    "UpdateMany": "Cập nhật nhiều",
    "LadiPageImport": "Import LadiPage",
    "ShopeeImport": "Import Shopee",
    "LazImport": "Import Lazada",
    "TiktokImport": "Import Tiktok",
    "TiktokImportBegin": "Import Tiktok Begin",
    "Export": "Export Excel",
    "Import": "Import Excel",
    "ImportTransport": "Cập nhật trạng thái pickup",
    "ImportTransportStatus": "Cập nhật trạng thái vận chuyển",
    "ImportRemittanceReport": "Import Remittance Report",
    "ExportSms": "Export SMS",
    "ImportSms": "Import SMS",
    "GenerateProducts": "Tạo sản phẩm tự động",
    "DetachNotTransportOrders": "Tách đơn hàng chưa vận chuyển",
    "CreateMany": "Tạo nhiều",
    "ImportPrepare": "Chuẩn bị Import",
    "ImportMktDomains": "Import Mkt Domains",
    "ImportChatPages": "Import Chat Pages",
    "ImportReturnPrepare": "Chuẩn bị Import Return",
}
