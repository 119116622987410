export default {
    "IndexSetStatusH": ["Tất cả đơn hàng", "Hủy đơn hàng"],
    "InvalidCheckItemUnsetInvalidStatus": ["Check trùng đơn", "Chọn"],
    "InvalidCheckItemSetStatusH": ["Check trùng đơn", "Hủy"],
    "LocationCheckSetLocationUserId": ["Check địa chỉ", "Chọn user"],
    "LocationCheckSetLocationStatusHt": ["Check địa chỉ", "Chuyển trạng thái hoàn thành"],
    "LocationCheckItemUpdate": ["Check địa chỉ", "Chỉnh sửa"],
    "PickUpPrepareSetLocationStatusKt": ["Chuẩn bị pick up", "Chuyển Telesale ODZ"],
    "TelesaleCheckSetTelesaleUserId": ["Check Telesale", "Select telesale"],
    "TelesaleCheckSetTelesaleStatusHt": ["Check Telesale", "Update Status to Complete"],
    "TelesaleCheckItemUpdate": ["Check Telesale", "Update"],
}
