export default {
    "ActionLog": "Lịch sử hoạt động",
    "DailyBonusSetting": "Cài đặt thưởng ngày",
    "Department": "Phòng",
    "Market": "Thị trường",
    "MktCost": "Chi phí MKT",
    "MktLink": "MKT Link",
    "MktPlatform": "Nền tảng MKT",
    "Order": "Order",
    "ProductCombo": "Combo sản phẩm",
    "ProductGroup": "Nhóm sản phẩm",
    "Product": "Sản phẩm",
    "Setting": "Cài đặt",
    "ShippingCompany": "ĐVVC",
    "Team": "Team",
    "User": "User",
    "WarehouseExport": "Xuất kho",
    "WarehouseImport": "Nhập kho",
    "WarehouseResetCommand": "Phiếu kiểm kê kho",
    "Warehouse": "Kho",
    "MonthlyCost": "Chi phí",
    "CostModifyCommand": "Cách bán",
    "WarehouseTransferCommand": "Chuyển kho",
    "PurchaseCommand": "Mua hàng",
    "AdsAgency": "Agency QC",
    "AdsAgencyAccount": "TKQC",
    "AdsAgencyBalanceChange": "Biến động số dư QC",
    "AdsBill": "Hóa đơn QC",
    "AdsCost": "CPQC",
    "AdsCurrency": "Đơn vị tiền tệ QC",
    "Shop": "Shop",
    "ShopWalletTransaction": "Giao dịch trên sàn",
}
